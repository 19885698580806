import { ILoginResponseContext } from "./LoginApi";

export interface IResumeRequest {
    SessionKey: string,
    Name: string,
    Surname: string,
    EmployeeKey: string,
}

export interface IResumeResponse {
    Success: boolean,
    Message: string,
    Context: ILoginResponseContext | null;
}

export function resume(_request: IResumeRequest): Promise<IResumeResponse> {
    const promise = new Promise<IResumeResponse>(async (resolve, reject) => {
        const response = await fetch('/api/resume', {
            method: 'POST',
            body: JSON.stringify(_request),
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            reject(response);
            return;
        }

        const jsonResponse = await response.json();
        resolve(jsonResponse);
    });
    (promise as any).name = `ResumeApi${0}`;
    return promise;
}
