import * as loginApi from './LoginApi';
import * as resumeApi from './ResumeApi';
import * as testSubmitApi from './TestSubmitApi';

export * from './LoginApi';
export * from './ResumeApi';
export * from './TestSubmitApi';

export const Api = {
    login: loginApi.login,
    resume: resumeApi.resume,
    testSubmit: testSubmitApi.testSubmit,
}
