import { IResumeRequest, IResumeResponse, ILoginRequest, ILoginResponse, Api } from '../api';
import { createStore, createSlice, createSliceReducer, StoreAction, StoreSliceStoreProvider, StoreSliceStoreSelector, useStore as genericUseStore, useStoreSelector as genericUseStoreSelector, useEffectSystem } from '@kuchenmeister/modules-react-effectstore';

const sliceReducer = createSliceReducer({
    reducers: {
        session: createSlice({
            name: 'session',
            initialState: { user: null as ILoginRequest, response: null as ILoginResponse },
            reducers: {
                'login': (_state, _action: StoreAction<{ user: ILoginRequest }>) => {
                    _state.user = _action.payload.user;
                },
                'loginSuccess': (_state, _action: StoreAction<{ response: ILoginResponse }>) => {
                    _state.response = _action.payload.response;
                },
                'loginFailed': (_state, _action: StoreAction<{ response: ILoginResponse }>) => {
                    _state.response = _action.payload.response;
                },
                'logoff': (_state) => {
                    _state.user = null;
                    _state.response = null;
                },
                'resume': (_state, _action: StoreAction<{ session: IResumeRequest }>) => {
                    _state.user = _action.payload.session;
                },
                'resumeSuccess': (_state, _action: StoreAction<{ response: IResumeResponse }>) => {
                    _state.response = _action.payload.response;
                },
                'resumeFailed': (_state, _action: StoreAction<{ response: IResumeResponse }>) => {
                    _state.response = _action.payload.response;
                },
            }
        })
    }
})

// create the store
export const store = createStore(sliceReducer);

export const useStore: StoreSliceStoreProvider<typeof sliceReducer> = genericUseStore;
export const useStoreSelector: StoreSliceStoreSelector<typeof sliceReducer> = genericUseStoreSelector;
export const storeActions = sliceReducer.actions;
export const effectSystem = useEffectSystem(store);
