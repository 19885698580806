/**
 * This is the landing page.
 */
import * as React from 'react';
import { Route, Routes } from 'react-router';
import { ContentPage } from './ContentPage';
import { TestPage } from './TestPage';
import { ErrorPage } from './ErrorPage';

import '../styles/LandingPage.scss'
import { LayoutComponent } from '../components/LayoutComponent';
import { TestContextProvider } from '../context/TestContextProvider';
import { storeActions, useStore, useStoreSelector } from '../store';


export interface IProps extends React.Props<any> {

}

export const LandingPage: React.FC<IProps> = (props) => {
    const store = useStore();
    const response = useStoreSelector(_ => _.session.response);

    async function logoff() {
        store.dispatch(storeActions.session.logoff());
    }

    return (
        <LayoutComponent
            Title={response?.Context?.ContextName}
            CSSStyles={response?.Context?.CSSClasses || []}
            imageUrl={response?.Context?.ImageUrl} logoffHandler={logoff}
        >
            <TestContextProvider>
                <Routes>
                    <Route path='/' element={<ContentPage />} />
                    <Route path='/*' element={<ErrorPage />} />
                    <Route path='/test' element={<TestPage />} />
                </Routes>
            </TestContextProvider>
        </LayoutComponent>
    )
}