/**
 * This is the landing page.
 */
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ITestSubmitResponse } from '../api';
import { useTranslation } from '@kuchenmeister/modules-react-translation';
import '../styles/TestSubmittedPage.scss';
import { LayoutComponent } from '../components/LayoutComponent';

export interface IProps extends React.Props<any> {

}

export interface IPageState {
    answers: any,
    response: ITestSubmitResponse,
    cssStyles: string[],
    imageUrl?: string
}

export const TestSubmittedPage: React.FC<IProps> = (props) => {

    const t = useTranslation();
    const l = useLocation();
    const pageState = l.state as IPageState;

    return (
        pageState ?
            (
                <LayoutComponent CSSStyles={pageState.cssStyles} imageUrl={pageState.imageUrl}>
                    <div className='center'>
                        <i className={'far ' + (
                            pageState.response.Context.Result ? 'fa-check-circle fa-size fa-green' : 'fa-times-circle fa-size fa-red'
                        )} />
                        <br />
                        <label className='result-text'>
                            {t('result.text')}:
                        </label>
                        <label className={'result ' + (pageState.response.Context.Result ? 'result-success' : 'result-failed')}>
                            {t(pageState.response.Context.Result ? "result.success" : "result.failed")}
                        </label>
                        <br />
                        <label className='result-correct-answers'>{t('result.correctAnswers', [pageState.response.Context.CorrectAnswers, pageState.response.Context.QuestionAnswers])} </label>
                        <br />
                    </div>
                    <Link className="btn btn-sm btn-login" to={'/'}><span>{t('navigate.toLogin')}</span></Link>
                </LayoutComponent>
            ) :
            (
                <LayoutComponent CSSStyles={[]}>
                    <div className='center'>
                        {t('error.expiredSession')}
                    </div>
                </LayoutComponent>
            )
    )
}