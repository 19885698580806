/**
 * This is the Answer page.
 */
import * as React from 'react';
import { useTranslation } from '@kuchenmeister/modules-react-translation';
import { Api, IAnswer } from 'src/api';
import '../styles/AnswerComponent.scss';

export interface IProps extends React.Props<any> {
    answer: IAnswer;
    id: number;
    questionNumber: number;
    clickHandler: (id: string) => void;
    isChecked: boolean;
}

export const AnswerComponent: React.FC<IProps> = (props) => {
    const t = useTranslation();

    return (
        <div className="row answerDiv">
            <label key={'o' + props.answer.Id + '_' + props.id}>
                <input className="inputAnswer" type={'radio'} name={'o' + props.questionNumber} value={props.answer.Id} onChange={() => { props.clickHandler(props.answer.Id) }} checked={props.isChecked} />
                {t(props.answer.CaptionKey as any)}
            </label>
        </div>
    )
}
