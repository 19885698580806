/**
 * This is the Answer page.
 */
import * as React from 'react';
import '../styles/AlertComponent.scss'

export type AlertTypes = 'info' | 'warning' | 'danger' | 'primary';

export interface IProps extends React.Props<any> {
    alertMessage?: string,
    showAlert: boolean,
    alertType?: AlertTypes,
    closeAlert: () => void,
}

export const AlertComponent: React.FC<IProps> = (props) => {
    const alertType = props.alertType || 'danger';

    return props.showAlert ? (
        <div className={'alert alert-dismissible fade show alert-' + alertType} role="alert">
            {
                typeof props.alertMessage !== 'undefined' ?
                    <strong>{props.alertMessage}</strong> : <></>
            }
            {
                typeof props.children !== 'undefined' ?
                    props.children : <></>
            }

            <button type="button" className="btn-close" aria-label="Close" onClick={props.closeAlert}></button>
        </div>
    ) : (<></>)
}