/**
 * This is the Question page.
 */
import * as React from 'react';
import { useTranslation } from '@kuchenmeister/modules-react-translation';
import { IQuestion } from '../api';
import { AnswerComponent } from './AnswerComponent'
import '../styles/QuestionComponent.scss';

export interface IProps extends React.Props<any> {
    question: IQuestion;
    questionNumber: number;
    answerHandler: (question: IQuestion, answerId: string) => void;
    selectedAnswerId: string;
}

export const QuestionComponent: React.FC<IProps> = (props) => {
    const t = useTranslation();

    let questionAnswers = [];

    props.question.Answers.forEach((o, ii) => {
        questionAnswers.push(
            <AnswerComponent key={ii} answer={o} id={ii} questionNumber={props.questionNumber} clickHandler={(id) => { props.answerHandler(props.question, id) }} isChecked={props.selectedAnswerId == o.Id} />
        );
    });

    return (
        <div>
            <label className="questionLabel">{t(props.question.CaptionKey as any)}</label>
            <div className="answers">
                {questionAnswers}
            </div>
        </div>
    )
}



//<label key={'o' + props.question.Id + '_' + ii}><input type={'radio'} name={'o' + props.question.Id} value={o.Id} onChange={
//    (e: React.ChangeEvent<HTMLInputElement>) => {
//        answers.current[props.question.Id] = e.target.value;
//    }
//} />{t(o.CaptionKey as any)}</label>)