import { ITranslationEntries } from '@kuchenmeister/modules-react-translation';

/** Login Request, things which are required to login into the Webapplication */
export interface ILoginRequest {
    Name: string,
    Surname: string,
    EmployeeKey: string,
    PresharedKey?: string
}

/** Document Informations */
export interface IDocument {
    CaptionKey: string,
    DescriptionKey: string,
    DefaultUrl: string,
    Urls: { [key: string]: string },
}

///Question Informations
export interface IQuestion {
    Id: string,
    CaptionKey: string,
    Answers: IAnswer[],
}

///Answer Information for the Questions
export interface IAnswer {
    Id: string,
    CaptionKey: string,
}

///Response from the login request from the Server
export interface ILoginResponseContext {
    SessionToken: string,
    ContextName: string,
    ImageUrl: string,
    TextKeyLeadIn: string,
    TextKeyLeadOut: string,
    AskForDataTracking: boolean,
    ShowTestPage: boolean,
    CSSClasses: string[],
    Documents: IDocument[],
    Questions: IQuestion[],
    Translations: ITranslationEntries,
}

///Response if the login failed or was a success
export interface ILoginResponse {
    Success: boolean,
    Message: string,
    ErrorCode?: number | null,
    Context: ILoginResponseContext | null;
}

/**
 * Login Post
 * @param _request {object} LoginRequest object
 * @returns {object} LoginResponse as promise
 */
export function login(_request: ILoginRequest): Promise<ILoginResponse> {
    const promise = new Promise<ILoginResponse>(async (resolve, reject) => {
        const response = await fetch('/api/login', {
            method: 'POST',
            body: JSON.stringify(_request),
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            reject(response);
            return;
        }

        const jsonResponse = await response.json();
        resolve(jsonResponse);
    });
    (promise as any).name = `LoginApi${0}`;

    return promise;
}
