import React from 'react';
import { ActionDispatchFunction, ActionReducer, combineReducers, createDispatcher } from '@kuchenmeister/modules-utilities';

/**
 * The action types for this context.
 */
export enum TestContextActions {
    ChangeAnswer = 'Test/ChangeAnswer',
    ClearAnswers = 'Test/ClearAnswers',
    ReplaceAnswers = 'Test/ReplaceAnswers',
}

/**
 * The payload for the action types.
 */
type Messages = {
    [TestContextActions.ChangeAnswer]: { questionId: string, answerId: string },
    [TestContextActions.ClearAnswers]: {},
    [TestContextActions.ReplaceAnswers]: { answers: { [key: string]: string } },
}

/**
 * The context for the react application.
 */
export interface ITestContext {
    state?: ITestContextState;
    dispatch?: ActionDispatchFunction<Messages>;
}

/**
 * The context state.
 */
export interface ITestContextState {
    currentAnswers: { [key: string]: string };
}

const CONTEXT = React.createContext<ITestContext>({});

const REDUCER: ActionReducer<ITestContextState, Messages> = (state, action) => {

    switch (action.type) {
        case TestContextActions.ChangeAnswer: {
            const nextState = {
                ...state,
                currentAnswers: {
                    ...state.currentAnswers,
                    [action.payload.questionId]: action.payload.answerId
                }
            };

            localStorage.setItem('lastAnswers', JSON.stringify(nextState.currentAnswers));
            return nextState;
        }
        case TestContextActions.ClearAnswers: {
            localStorage.removeItem('lastAnswers');
            return {
                ...state,
                currentAnswers: {
                }
            };
        }
        case TestContextActions.ReplaceAnswers: {
            return {
                ...state,
                currentAnswers: action.payload.answers
            };
        }
        default:
            return state;
    }
}

const INITIAL_VALUE: ITestContextState = {
    currentAnswers: {}
};

export interface IProps extends React.Props<any> {
}

/**
 * The context provider for the generic context informations.
 * @param props
 */
export const TestContextProvider = (props: IProps) => {

    const [state, dispatch] = React.useReducer(REDUCER, INITIAL_VALUE);
    const [context, setContext] = React.useState<ITestContext>(() => { return { state, dispatch: createDispatcher(dispatch) }; });

    React.useEffect(() => {
        const lastAnswers = localStorage.getItem('lastAnswers');

        if (lastAnswers) {
            context.dispatch(TestContextActions.ReplaceAnswers, { answers: JSON.parse(lastAnswers) });
        }

        return () => {
            localStorage.removeItem('lastAnswers');
        };
    }, []);

    React.useEffect(() => {
        setContext(_ => {
            return {
                ..._,
                state
            };
        });
    }, [state])

    return (
        <CONTEXT.Provider
            value={context}
        >
            {props.children}
        </CONTEXT.Provider>
    );
}

/** The consumer for the context. */
export const TestContextConsumer = CONTEXT.Consumer;

/**
 * Access the user context information.
 */
export function useTestContext() {
    const context = React.useContext(CONTEXT);
    if (context === undefined)
        throw new Error('useTestContext must be used within a TestContextProvider');
    return context.state;
};

/**
 * Access the dispatcher function for the user context.
 */
export function useTestContextDispatcher() {
    const context = React.useContext(CONTEXT);
    if (context === undefined)
        throw new Error('useTestContext must be used within a TestContextProvider');
    return context.dispatch;
}