/**
 * This is the landing page.
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@kuchenmeister/modules-react-translation';

export interface IProps extends React.Props<any> {

}

export const ErrorPage: React.FC<IProps> = (props) => {

    const t = useTranslation();
    return (
        <div>
            {t('error.pageNotFound')} <br />
            <br />
            <Link to={'/'} >{t('navigate.toHomepage')}</Link>
        </div>
    )
}