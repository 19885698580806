import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Utilities from '@kuchenmeister/modules-utilities';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Application } from './main.app';

const log = Utilities.logging.getLogger('training.main');

// for internet explorer
import { polyfill } from 'es6-promise'; polyfill();
if (typeof Promise.prototype.finally === 'undefined') { Promise.prototype.finally = Promise.prototype.then; }

// initialize the logging system
Utilities.logging.configure({
    minLevels: {
        'training': 'debug',
        //'utl.ctx.trans': 'debug',
        //'eco.ctx.trans': 'info',
    }
}).registerConsoleLogger();

// render the application
log.debug('ReactDOM.render')
ReactDOM.render(
    <Application />,
    document.getElementById('app')
);
log.debug('ReactDOM.render - done')
