/**
 * This is the Layout component.
 */
import * as React from 'react';
import { LanguageContextActions, useLanguageContext, useTranslation, useLanguages } from '@kuchenmeister/modules-react-translation';
import '../styles/QuestionComponent.scss';

export interface IProps extends React.Props<any> {
	CSSStyles: string[],
	Title?: string,
	imageUrl?: string,
	logoffHandler?: () => void,
	Language?: string,
}

export const LayoutComponent: React.FC<IProps> = (_props) => {
	const t = useTranslation();
	const lang = useLanguageContext();
	const Languages = useLanguages();

	function changeLanguage(_languageKey: string) {
		lang.dispatch(LanguageContextActions.ChangeLanguage, { language: _languageKey });
	}

	return (
		<div className={_props.CSSStyles.join(' ')}>
			<div className="container container-sm">
				<div className="LandingPage">
					<div className="site-heading text-small-caps">
						<div className="row">
							<div className="col col-3">
								{
									_props.imageUrl ?
										<img className="mandatorLogo" src={_props.imageUrl} alt="Logo" />
										: <></>
								}
							</div>
							<div className="col col-6">
								{
									_props.Title ?
										<h3>{_props.Title}</h3>
										: <></>
								}
							</div>
							<div className="col col-3">
								<div className="input-group input-group-sm language-margin">
									<select className="form-select" onChange={(e) => changeLanguage(e.target.value)} value={t.language}>
										{Languages.map(l => <option value={l.value} key={l.value}>{l.description}</option>)}
									</select>
									{
										_props.logoffHandler ?
											<button className="btn btn-sm btn-login" onClick={_props.logoffHandler}>{t('logoff')}</button>
											: <></>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="contentDiv">
						{_props.children}
					</div>
				</div>
			</div>
		</div>
	)
}
