/**
 * This is the main application.
 */
import * as React from 'react';
import { LanguageContextProvider, TranslationContextProvider, ILanguageDefinition, TranslationEntryBuilder } from '@kuchenmeister/modules-react-translation';
import { RootPage } from './pages/RootPage';
import { store } from './store';
import './styles/Training.scss';
import { GlobalEventStoreProvider } from '@kuchenmeister/modules-react-effectstore';
import { _Example } from './pages/_example';

export interface IProps extends React.Props<any> {

}

//const theme = document.head.appendChild<HTMLStyleElement>(document.createElement('style'));
//theme.innerHTML = `/* The current theme */
//:root { 
//    --km-btn-bg-color:      #efefef;
//    --km-btn-border-color:  #0a0a0a;
//    --km-btn-color:         #000000;
//}`

//document.body.style.setProperty('--km-btn-bg-color', '#efefef');
//document.body.style.setProperty('--km-btn-border-color', '#0a0a0a');

declare global {
    type ITranslationKeys = { [key in keyof typeof translations['entries']]: any };
    interface TranslationKeys extends ITranslationKeys { }
    interface TranslationLanguages {
        'pl': 'Polska',
        'ita': 'Italian',
        'bul': 'Bulgarian',
        'ron': 'Romanian',
    }
}

const allLanguages: Array<ILanguageDefinition> = [
    { value: 'de', description: 'Deutsch | German', descriptionEn: 'German' },
    { value: 'en', description: 'English | English', descriptionEn: 'English' },
    { value: 'pl', description: 'Polska | Polish', descriptionEn: 'Polish' },
    { value: 'ita', description: 'Italiano | Italian', descriptionEn: 'Italian' },
    { value: 'bul', description: 'български | Bulgarian', descriptionEn: 'Bulgarian' },
    { value: 'ron', description: 'Română | Romania', descriptionEn: 'Romania' },
    { value: 'dan', description: 'Dansk | Danish', descriptionEn: 'Dansk' },
]

// TODO: ordentliche Sätze!
// Translations for the whole Page
const translations = TranslationEntryBuilder({
    // #region General

    'logoff': {
        'de': 'Abmelden',
        'en': 'Log out',
        'pl': 'Wyloguj się',
        'ita': 'Disconnettersi',
        'bul': 'Излез от профила си',
        'ron': 'Deconectați- vă',
        'dan': 'Log af',
    },

    // #endregion

    // #region Navigate

    'navigate.toHomepage': {
        'de': 'Zurück zur Hauptseite',
        'en': 'Back to the home page',
        'pl': 'Powrót do strony głównej',
        'ita': 'Torna alla pagina principale',
        'bul': 'Обратно към основната страница',
        'ron': 'Înapoi la pagina principală',
        'dan': 'Tilbage til startsiden',
    },
    'navigate.toContent': {
        'de': 'Zum Inhalt',
        'en': 'To content',
        'pl': 'Do treści',
        'ita': 'Al contenuto',
        'bul': 'Към съдържанието',
        'ron': 'La conținut',
        'dan': 'Gå til indhold',
    },
    'navigate.toLogin': {
        'de': 'Zur Anmeldung',
        'en': 'To login',
        'pl': 'Do rejestracji',
        'ita': 'Alla registrazione',
        'bul': 'За регистрацията',
        'ron': 'La înregistrare',
        'dan': 'Til registrering',
    },

    // #endregion

    // #region Error messages

    'error.missingLoginData': {
        'de': 'Bitte geben Sie Ihre Anmeldedaten an.',
        'en': 'Please enter your login details.',
        'pl': 'Wprowadź swoje dane logowania.',
        'ita': 'Inserire i dati di accesso.',
        'bul': 'Моля, въведете данните си за вход.',
        'ron': 'Vă rugăm să introduceți datele dumneavoastră de conectare.',
        'dan': 'Indtast venligst dine login oplysninger.',

    },
    'error.pageNotFound': {
        'de': 'Die konnte Seite nicht gefunden werden.',
        'en': 'The page could not be found.',
        'pl': 'Nie można znaleźć tej strony.',
        'ita': 'La pagina non è stata trovata.',
        'bul': 'Страницата не може да бъде намерена.',
        'ron': 'Pagina nu a putut fi găsită.',
        'dan': 'Siden kunne ikke findes.',
    },
    'error.loginFailed': {
        'de': 'Die Anmeldung ist fehlgeschlagen.',
        'en': 'The login failed.',
        'pl': 'Rejestracja nie powiodła się',
        'ita': 'La registrazione è fallita.',
        'bul': 'Регистрацията е неуспешна.',
        'ron': 'Înregistrarea a eșuat.',
        'dan': 'Login mislykkedes.',
    },
    'error.submitFailed': {
        'de': 'Der Test konnte nicht abgeschlossen werden.',
        'en': 'Test could not be completed.',
        'pl': 'Test nie mógł zostać ukończony.',
        'ita': 'Non è stato possibile completare il test.',
        'bul': 'Тестът не може да бъде завършен.',
        'ron': 'Testul nu a putut fi finalizat.',
        'dan': 'Testen kunne ikke gennemføres.',
    },
    'error.trainingOrEmployeeKeyInvalid': {
        'de': 'Die Anmeldedaten sind ungültig.',
        'en': 'Employeee key is invalid',
        'pl': 'Dane logowania są nieprawidłowe.',
        'ita': 'I dati di accesso non sono validi.',
        'bul': 'Данните за вход са невалидни.',
        'ron': 'Datele de conectare nu sunt valide.',
        'dan': 'Login oplysningerne er ugyldige.',
    },
    'error.saveResults': {
        'de': 'Speichern Sie ihre Ergebnisse',
        'en': 'Save your results',
        'pl': 'Zapisz wyniki',
        'ita': 'Salvare i risultati',
        'bul': 'Запазване на резултатите',
        'ron': 'Salvează-ți rezultatele',
        'dan': 'Gem dine resultater',
    },
    'error.trainingNotFound': {
        'de': 'Es wurde kein Training gefunden',
        'en': 'Training not found',
        'pl': 'Nie znaleziono szkoleń',
        'ita': 'Nessuna formazione trovata',
        'bul': 'Не е намерено обучение',
        'ron': 'Nu s-a găsit nicio formare',
        'dan': 'Ingen træning blev fundet',
    },
    'error.expiredSession': {
        'de': 'Die Sitzung ist abgelaufen',
        'en': 'The session is expired',
        'pl': 'Sesja wygasła',
        'ita': 'La sessione è scaduta',
        'bul': 'Сесията е изтекла',
        'ron': 'Sesiunea a expirat',
        'dan': 'Sessionen er udløbet',
    },
    'error.invalidSession': {
        'de': 'Ungültige Sitzung',
        'en': 'Invalid session',
        'pl': 'Nieprawidłowa sesja',
        'ita': 'Sessione non valida',
        'bul': 'Невалидна сесия',
        'ron': 'Sesiune invalidă',
        'dan': 'Ugyldig session',
    },
    'error.endedSession': {
        'de': 'Die Sitzung wurde beendet',
        'en': 'The session ended',
        'pl': 'Spotkanie zostało zamknięte',
        'ita': 'La riunione è stata chiusa',
        'bul': 'Заседанието беше закрито',
        'ron': 'Ședința a fost închisă.',
        'dan': 'Sessionen er afsluttet',
    },

    // #endregion

    // #region Login page

    'loginPage.registration': {
        'de': 'Anmeldung zur Onlineunterweisung',
        'en': 'Login for online instruction',
        'pl': 'Rejestracja na instrukcja online',
        'ita': 'Accedi per istruzioni online',
        'bul': 'Влезте за онлайн инструкции',
        'ron': 'Conectați-vă pentru instrucțiuni online',
        'dan': 'Tilmelding til online undervisning',
    },
    'loginPage.name': {
        'de': 'Vorname',
        'en': 'Name',
        'pl': 'Imię',
        'ita': 'Nome',
        'bul': 'име',
        'ron': 'Nume',
        'dan': 'Fornavn',
    },
    'loginPage.surname': {
        'de': 'Nachname',
        'en': 'Surname',
        'pl': 'Nazwisko',
        'ita': 'Cognome',
        'bul': 'Фамилия',
        'ron': 'Nume de familie',
        'dan': 'Efternavn',
    },
    'loginPage.shared': {
        'de': 'Schulungspasswort',
        'en': 'Password for training',
        'pl': 'Hasło szkolenia',
        'ita': 'Password di allenamento',
        'bul': 'Парола за обучение',
        'ron': 'Parola de antrenament',
        'dan': 'Træningsadgangskode',
    },
    'loginPage.language': {
        'de': 'Sprache',
        'en': 'Language',
        'pl': 'Język',
        'ita': 'Lingua',
        'bul': 'език',
        'ron': 'Limba',
        'dan': 'Sprog',
    },
    'loginPage.employeeKey': {
        'de': 'Personalnummer',
        'en': 'Employee key',
        'pl': 'Numer Personelu',
        'ita': 'Chiave del dipendente',
        'bul': 'Ключ на служител',
        'ron': 'Cheia angajatului',
        'dan': 'Personalenummer',
    },
    'loginPage.commit': {
        'de': 'Überprüfen',
        'en': 'Check',
        'pl': 'Sprawdź',
        'ita': 'Controllo',
        'bul': 'Проверете',
        'ron': 'Verifica',
        'dan': 'Tjekke over',
    },
    'loginPage.performing': {
        'de': 'Anmeldedaten werden überprüft',
        'en': 'Registration data will be checked',
        'pl': 'Poświadczenia są weryfikowane',
        'ita': 'I dati di registrazione verranno controllati',
        'bul': 'Данните за регистрация ще бъдат проверени',
        'ron': 'Datele de înregistrare vor fi verificate',
        'dan': 'Legitimationsoplysninger er verificeret',
    },
    'loginPage.sessionContinue': {
        'de': 'Die Sitzung wird fortgesetzt',
        'en': 'The session will be continued',
        'pl': 'Sesja będzie kontynuowana',
        'ita': 'La sessione proseguirà',
        'bul': 'Сесията ще бъде продължена',
        'ron': 'Sesiunea va fi continuată',
        'dan': 'Sessionen fortsætter',
    },

    // #endregion

    // #region Content page

    'contentPage.onlineInstruction': {
        'de': 'Onlineunterweisung',
        'en': 'Online instruction',
        'pl': 'Instruktaż online',
        'ita': 'Istruzione online',
        'bul': 'Онлайн инструкция',
        'ron': 'Instruire online',
        'dan': 'Online instruktion',
    },
    'contentPage.welcome': {
        'de': 'Willkommen zur Onlineunterweisung {0} {1},',
        'en': 'Welcome to the online instruction {0} {1},',
        'pl': 'Witamy w internetowej instrukcji {0} {1},',
        'ita': 'Benvenuti all\'istruzione online {0} {1},',
        'bul': 'Добре дошли в онлайн инструкцията {0} {1},',
        'ron': 'Bine ați venit la instructajul online {0} {1},',
        'dan': 'Velkommen til online instruktion {0} {1},',
    },
    'contentPage.documentLink': {
        'de': 'Link',
        'en': 'Link',
        'pl': 'Połączyć',
        'ita': 'Collegamento',
        'bul': 'Връзка',
        'ron': 'Legătură',
        'dan': 'Link',
    },
    'contentPage.documentDescription': {
        'de': 'Beschreibung',
        'en': 'Description',
        'pl': 'Opis',
        'ita': 'Descrizione',
        'bul': 'Описание',
        'ron': 'Descriere',
        'dan': 'Beskrivelse',
    },
    'contentPage.noDocuments': {
        'de': 'Es sind keine Unterlagen vorhanden.',
        'en': 'There are no documents available.',
        'pl': 'Brak dostępnych dokumentów.',
        'ita': 'Non ci sono documenti disponibili.',
        'bul': 'Няма налични документи.',
        'ron': 'Nu există documente disponibile.',
        'dan': 'Der er ingen dokumenter.',
    },
    'contentPage.startTest': {
        'de': 'Test starten',
        'en': 'Start test',
        'pl': 'Rozpocznij test',
        'ita': 'Inizia la prova',
        'bul': 'Започнете теста',
        'ron': 'Începeți testul',
        'dan': 'Start testen',
    },

    // #endregion

    // #region Test page

    'testPage.headline': {
        'de': 'Die Fragen finden Sie in den Dokumenten. Bitte beantworten Sie alle Fragen. Es ist immer nur eine Antwort richtig.',
        'en': 'You can find the questions in the documents. Please answer all questions. Only one answer is correct at a time.',
        'pl': 'Pytania znajdują się w dokumentach. Proszę odpowiedzieć na wszystkie pytania. W danym momencie tylko jedna odpowiedź jest prawidłowa.',
        'ita': 'Le domande sono riportate nei documenti. Rispondere a tutte le domande. Solo una risposta alla volta è corretta.',
        'bul': 'Въпросите ще намерите в документите. Моля, отговорете на всички въпроси. Само един отговор е верен в даден момент.',
        'ron': 'Veți găsi întrebările în documente. Vă rugăm să răspundeți la toate întrebările. Doar un singur răspuns este corect la un moment dat',
        'dan': 'Du kan finde spørgsmålene i dokumenterne. Svar venligst på alle spørgsmål. Der er altid kun ét rigtigt svar.',
    },
    'testPage.noQuestions': {
        'de': 'Es sind keine Fragen vorhanden.',
        'en': 'There are no Questions available.',
        'pl': 'Nie ma żadnych pytań.',
        'ita': 'Non ci sono domande',
        'bul': 'Няма налични въпроси',
        'ron': 'Nu sunt întrebări.',
        'dan': 'Der er ingen spørgsmål.',
    },
    'testPage.dataTrackingError': {
        'de': 'Sie müssen dem Datenschutz Text zustimmen.',
        'en': 'You need to agree the Terms for tracking data.',
        'pl': 'Użytkownik musi wyrazić zgodę na tekst dotyczący ochrony prywatności.',
        'ita': 'È necessario accettare il testo sulla privacy',
        'bul': 'Трябва да се съгласите с текста за поверителност',
        'ron': 'Trebuie să fiți de acord cu textul privind confidențialitatea.',
        'dan': 'Du skal acceptere privatlivspolitikken.',
    },
    'testPage.notAllQuestionsAnswered': {
        'de': 'Sie müssen alle Fragen beantworten.',
        'en': 'You need to answer all questions.',
        'pl': 'Musisz odpowiedzieć na wszystkie pytania.',
        'ita': 'È necessario rispondere a tutte le domande.',
        'bul': 'Трябва да отговорите на всички въпроси.',
        'ron': 'Trebuie să răspundeți la toate întrebările.',
        'dan': 'Du skal besvare alle spørgsmål.',
    },
    'testPage.clearAnswers': {
        'de': 'Antworten zurücksetzten',
        'en': 'Reset answers',
        'pl': 'Zresetuj odpowiedzi',
        'ita': 'Reimposta le risposte',
        'bul': 'Нулиране на отговорите',
        'ron': 'Resetați răspunsurile',
        'dan': 'Nulstil svar',
    },
    'testPage.alreadyAnswers': {
        'de': 'Sie haben bereits vorausgewählte Antworten, wenn Sie diese löschen möchten klicken Sie ',
        'en': 'You have already preselected answers, if you want to delete them click ',
        'pl': 'Masz już wstępnie wybrane odpowiedzi, jeśli chcesz je usunąć, kliknij przycisk ',
        'ita': 'Le risposte sono già state preselezionate, se si desidera eliminarle fare clic su ',
        'bul': 'Вече имате предварително избрани отговори, ако искате да ги изтриете, щракнете върху ',
        'ron': 'Aveți deja răspunsuri preselectate, dacă doriți să le ștergeți faceți clic pe ',
        'dan': 'Du har allerede forudvalgte svar, hvis du vil slette dem, klik ',
    },
    'testPage.alreadyAnswersButtonText': {
        'de': 'hier',
        'en': 'here',
        'pl': 'tutaj',
        'ita': 'qui',
        'bul': 'тук',
        'ron': 'aici',
        'dan': 'her',
    },
    'testPage.dataTracking': {
        'de': 'Ich bestätige hiermit, dass ich die oben angegebene Person bin und ich diese Fragen selbstständig und ohne fremde Hilfe ausgefüllt habe. Identifizierende Daten wie IP- Adresse, benutzter Browser, Ort der Ausführung, der Start sowieso das Absenden des Tests mit meinen Antworten gespeichert und zum Zweck der Auswertung und Validierung des Fragebogens genutzt werden darf. Weitere Informationen können Sie bei Ihrem/Ihrer Sicherheitsbeauftragten anfragen.',
        'en': 'I hereby confirm that I am the person indicated above and that I have completed these questions independently and without outside assistance. Identifying data such as IP address, browser used, place of execution, the start anyway the submission of the test with my answers may be stored and used for the purpose of evaluation and validation of the questionnaire. For more information, please contact your security officer.',
        'pl': 'Niniejszym potwierdzam, że jestem osobą wskazaną powyżej oraz że wypełniłem/am te pytania samodzielnie i bez pomocy osób trzecich. Niniejszym potwierdzam, że dane identyfikacyjne, takie jak adres IP, używana przeglądarka, miejsce wykonania, rozpoczęcie i wysłanie testu z moimi odpowiedziami mogą być przechowywane i wykorzystywane w celu oceny i walidacji kwestionariusza. Aby uzyskać więcej informacji, należy skontaktować się z pracownikiem ochrony.',
        'ita': 'Con la presente confermo di essere la persona sopra indicata e di aver compilato queste domande in modo autonomo e senza alcun aiuto esterno. Confermo che i dati identificativi come l\'indirizzo IP, il browser utilizzato, il luogo di esecuzione, l\'inizio e l\'invio del test con le mie risposte possono essere memorizzati e utilizzati ai fini della valutazione e della convalida del questionario.Per ulteriori informazioni, contattare l\'addetto alla sicurezza.',
        'bul': 'С настоящото потвърждавам, че аз съм лицето, посочено по-горе, и че съм попълнил тези въпроси самостоятелно и без външна помощ. С настоящото потвърждавам, че идентификационните данни, като IP адрес, използван браузър, място на изпълнение, начало и подаване на теста с моите отговори, могат да бъдат съхранявани и използвани за целите на оценяването и валидирането на въпросника. За допълнителна информация се обърнете към вашия служител по сигурността.',
        'ron': 'Confirm prin prezenta că sunt persoana indicată mai sus și că am completat aceste întrebări în mod independent și fără ajutor extern. Confirm prin prezenta că datele de identificare, cum ar fi adresa IP, browserul utilizat, locul de execuție, începutul și trimiterea testului cu răspunsurile mele pot fi stocate și utilizate în scopul evaluării și validării chestionarului. Pentru informații suplimentare, vă rugăm să vă adresați ofițerului de securitate.',
        'dan': 'Jeg bekræfter hermed, at jeg er ovennævnte person, og at jeg har besvaret disse spørgsmål selvstændigt og uden hjælp udefra. Identificerende data som IP-adresse, anvendt browser, udførelsessted, start og afsendelse af testen med mine svar kan gemmes og bruges med det formål at evaluere og validere spørgeskemaet. Du kan anmode om yderligere oplysninger fra din sikkerhedsofficer.',
    },
    'testPage.submit': {
        'de': 'Absenden',
        'en': 'Submit',
        'pl': 'Składać',
        'ita': 'Invia',
        'bul': 'Изпращане',
        'ron': 'Trimite',
        'dan': 'Indsend',
    },

    // #endregion

    // #region Test submitted page

    'result.text': {
        'de': 'Das ist Ihr Ergebnis',
        'en': 'This is your result',
        'pl': 'To jest Twój wynik',
        'ita': 'Questo è il risultato',
        'bul': 'Това е вашият резултат',
        'ron': 'Acesta este rezultatul dvs',
        'dan': 'Dette er dit resultat',
    },
    'result.success': {
        'de': 'Bestanden',
        'en': 'Success',
        'pl': 'Powodzenie',
        'ita': 'Successo',
        'bul': 'успех',
        'ron': 'Succes',
        'dan': 'Bestået',
    },
    'result.failed': {
        'de': 'Durchgefallen',
        'en': 'Failed',
        'pl': 'Przegrany',
        'ita': 'Fallito',
        'bul': 'се провали',
        'ron': 'A eșuat',
        'dan': 'Mislykkedes',
    },
    'result.correctAnswers': {
        'de': 'Sie haben {0}/{1} richtige Antworten gehabt.',
        'en': 'You had {0}/{1} correct answers.',
        'pl': 'Miałeś {0}/{1} poprawnych odpowiedzi.',
        'ita': 'Le risposte corrette erano {0}/{1}.',
        'bul': 'Имате {0}/{1} верни отговори.',
        'ron': 'Ați avut {0}/{1} răspunsuri corecte.',
        'dan': 'Du havde {0}/{1} rigtige svar.',
    }

    // #endregion
});

// Load language from localStorage
let startLanguage = localStorage.getItem('language');
if (!startLanguage) startLanguage = 'de';

// First Layer of the Webapplication
export const Application: React.FC<IProps> = (props) => {

    //return (<_Example />)

    return (
        <GlobalEventStoreProvider
            store={store}>
            <LanguageContextProvider
                language={startLanguage}
                allLanguages={allLanguages}
                onLanguageChanged={_ => localStorage.setItem('language', _)}
            >
                <TranslationContextProvider
                    id={'global'}
                    translations={translations.entries}
                >
                    <div className="page-wrap">
                        <RootPage />
                    </div>
                    <div className="site-footer">
                        © 2016 - 2022 <a className='link' href={'https://www.freemind-it.de/de'} target={'_blank'}>Free Mind IT GmbH</a> | <a className='link' href={'https://www.freemind-it.de/de/Impressum'} target={'_blank'}>Impressum</a>
                    </div>
                </TranslationContextProvider >
            </LanguageContextProvider>
        </GlobalEventStoreProvider>
    )
}