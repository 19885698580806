/**
 * This is the landing page.
 */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@kuchenmeister/modules-react-translation';
import { useStoreSelector } from '../store';

export interface IProps extends React.Props<any> {

}

export const ContentPage: React.FC<IProps> = (props) => {

    const t = useTranslation();
    const response = useStoreSelector(_ => _.session.response);
    const user = useStoreSelector(_ => _.session.user);

    function updateDocuments() {
        let documents = [];

        if (response?.Context?.Documents) {
            response.Context.Documents.forEach(_ => {
                let url = _.DefaultUrl;

                if (_.Urls && t.language in _.Urls)
                    url = _.Urls[t.language];

                documents.push(
                    <tr key={_.CaptionKey}>
                        <td>
                            {/*<i className="far fa-file-alt"></i>*/}
                            <a href={url} target={'_blank'}>
                                {t(_.CaptionKey as any)}
                            </a>
                        </td>
                        <td>
                            <span>{t(_.DescriptionKey as any)}</span>
                        </td>
                    </tr>
                )
            });
        }

        return documents;
    }

    const [documents, setDocuments] = React.useState(updateDocuments); 

    React.useEffect(() => {
        setDocuments(updateDocuments());
    }, [response, t]);

    return (
        <div>
            <h3>{t('contentPage.onlineInstruction')}</h3>
            <p>{t('contentPage.welcome', [user?.Name, user?.Surname])} </p>
            <div className="leadIn">
                <p>{t(response?.Context?.TextKeyLeadIn as any)}</p>
            </div>
            {
                documents?.length > 0 ?
                    <div className="testDocuments">
                        <table className="table table-borderless table-width">
                            <thead>
                                <tr>
                                    <th>{t('contentPage.documentLink')}</th>
                                    <th>{t('contentPage.documentDescription')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documents}
                            </tbody>
                        </table>
                    </div>
                    : <label>{t('contentPage.noDocuments')}</ label>
            }
            {
                response?.Context?.TextKeyLeadOut ? 
                    <div className="leadOut">
                        <p>{t(response?.Context?.TextKeyLeadOut as any)}</p>
                    </div>
                    : <></>
            }
            {
                response?.Context?.ShowTestPage && response?.Context?.Questions?.length >= 1 ?
                    <div className="testLink">
                        <div className="row">
                            <div className="logOffButton">
                                <Link className="btn btn-sm btn-login " to={'/test'}> <span>{t('contentPage.startTest')}</span></Link>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
           
        </div>
    )
}
