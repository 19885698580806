import { IResumeRequest } from "../api";

/**
 * The key of the local storage.
 */
const LOCAL_STORAGE_KEY = 'session';

/**
 * The current version of the local storage.
 */
const LOCAL_STORAGE_VERSION = 1;

/**
 * The interface for the local storage. Version needs to be updated when payload changes!
 */
export interface ILocalStorage {
    Version: number,
    Payload: ILocalStoragePayload,
}

/**
 * The interface for the payload of the local storage. Every property must be optional!
 */
export interface ILocalStoragePayload {
    Request?: IResumeRequest
}

/**
 * The session store helper. 
 */
export const sessionStore = {

    /**
     * Loads the session from the local storage and returns it.
     */
    load: (): ILocalStoragePayload => {
        const session = localStorage.getItem(LOCAL_STORAGE_KEY);

        if (session) {
            try {
                const o = JSON.parse(session) as ILocalStorage;
                if (o.Version != LOCAL_STORAGE_VERSION) {
                    return null;
                }
                return o.Payload;
            }
            catch (e) {
                return null;
            }
        }

        return null;
    },

    /**
     * Store the new payload in the local storage.
     * @param _payload
     */
    store: (_payload: ILocalStoragePayload) => {
        const session: ILocalStorage = {
            Version: LOCAL_STORAGE_VERSION,
            Payload: _payload,
        }

        const data = JSON.stringify(session);
        localStorage.setItem(LOCAL_STORAGE_KEY, data);
    },

    /**
     *  Removes the session from the local storage.
     */
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    },

    /**
     * Check if the session is available in the locale storage.
     */
    exists: () => {
        return localStorage.getItem(LOCAL_STORAGE_KEY) !== null;
    }
}