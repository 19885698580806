/**
 * This is the landing page.
 */
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Api } from '../api';
import { useTranslation } from '@kuchenmeister/modules-react-translation';
import { QuestionComponent } from '../components/QuestionComponent';
import { AlertComponent } from '../components/alertComponent'
import { IPageState } from './TestSubmittedPage';
import '../styles/TestPage.scss'
import { TestContextActions, useTestContext, useTestContextDispatcher } from '../context/TestContextProvider';
import { sessionStore } from '../utils/LocalStorageHelper';
import { storeActions, useStore, useStoreSelector } from '../store';

export interface IProps extends React.Props<any> {

}

export const TestPage: React.FC<IProps> = (props) => {

    const t = useTranslation();
    const n = useNavigate();
    const store = useStore();
    const response = useStoreSelector(_ => _.session.response);
    const testContext = useTestContext();
    const testContextDP = useTestContextDispatcher();

    const [warningMessage, setWarning] = React.useState<JSX.Element>(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [hasSubmited, setHasSubmited] = React.useState(false);
    const [canClearAnswers, setCanClearAnswers] = React.useState(() => Object.keys(testContext.currentAnswers).length > 0);
    const [showCanClearAnswersAlert, setShowCanClearAnswersAlert] = React.useState(false);
    const [AskForDataTracking, setAskForDataTracking] = React.useState(false);

    function updateQuestions() {
        let questions = [];

        response?.Context?.Questions?.forEach((_, i) => {
            questions.push(
                <QuestionComponent key={i} question={_} questionNumber={i} selectedAnswerId={testContext.currentAnswers[_.Id]} answerHandler={(a, b) => {
                    testContextDP(TestContextActions.ChangeAnswer, { questionId: a.Id, answerId: b });
                }} />
            );
        })

        return questions;
    }

    const [questions, setQuestions] = React.useState(updateQuestions);

    React.useEffect(() => {
        setQuestions(updateQuestions());
        setCanClearAnswers(Object.keys(testContext.currentAnswers).length > 0);
    }, [response, t, testContext]);

    React.useEffect(() => {
        if (Object.keys(testContext.currentAnswers).length > 0) {
            setShowCanClearAnswersAlert(true);
        }
    }, []);

    React.useEffect(() => {
        if (hasSubmited)
            hasErrors();
    }, [hasSubmited, t.language]);

    async function submitTest(event: React.FormEvent) {
        event.preventDefault();

        setHasSubmited(true);

        if (!hasErrors()) {
            const resp = await Api.testSubmit({
                SessionToken: response.Context.SessionToken,
                Answers: testContext.currentAnswers,
            })
            if (resp.Success) {
                sessionStore.remove();

                let pageState: IPageState = {
                    answers: testContext.currentAnswers,
                    response: resp,
                    cssStyles: response.Context.CSSClasses,
                    imageUrl: response.Context.ImageUrl
                };

                store.dispatch(storeActions.session.logoff());
                n('/test/submit', { state: pageState });
                testContextDP(TestContextActions.ClearAnswers, {});
            }
            else {
                setWarning(<label>{t(resp.Message as any)}</label>);
                setShowAlert(true);
            }
        }
    }

    function checkAllQuestions() {
        for (let i = 0; i < response.Context.Questions.length; i++) {
            const q = response.Context.Questions[i];
            if (typeof testContext.currentAnswers[q.Id] === 'undefined') {
                return false;
            }
        }
        return true;
    }

    function toggleAskForDataTracking() {
        setAskForDataTracking(!AskForDataTracking);
    }

    function clearAnswers(e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        testContextDP(TestContextActions.ClearAnswers, {});
        setShowCanClearAnswersAlert(false);
    }

    function hasErrors() {
        var errorMessage = [];

        if (!AskForDataTracking && response?.Context?.AskForDataTracking) {
            errorMessage.push(t('testPage.dataTrackingError'));
        }

        if (!checkAllQuestions()) {
            errorMessage.push(t('testPage.notAllQuestionsAnswered'))
        }

        if (errorMessage.length > 0) {
            const msgContent = [];

            errorMessage.forEach((v, i) => {
                msgContent.push(
                    <li key={i}>
                        {v}
                    </li>
                )
            })

            setWarning(
                <ul>
                    {msgContent}
                </ul>
            );

            setShowAlert(true);
            return true;
        }
        else {
            setWarning(<></>);
            setShowAlert(false);
        }

        return false;
    }

    return (
        response?.Context?.Questions.length > 0 ?
            (
                <div>
                    <h3>{t('testPage.headline')}</h3>
                    <AlertComponent alertMessage={t('testPage.alreadyAnswers')} showAlert={showCanClearAnswersAlert} alertType={'warning'} closeAlert={() => { setShowCanClearAnswersAlert(false) }}>
                        <a onClick={clearAnswers}><strong> <u>{t('testPage.alreadyAnswersButtonText')}</u></strong></a>
                    </AlertComponent>
                    <form onSubmit={submitTest}>
                        {questions}
                        <div className="row">
                            {
                                response?.Context?.AskForDataTracking ?
                                    <label>
                                        <input className="DataTrackCheckbox" type="checkbox" checked={AskForDataTracking} onChange={toggleAskForDataTracking} />
                                        {t('testPage.dataTracking')}
                                    </label>
                                    : <></>
                            }
                        </div>
                        <AlertComponent showAlert={showAlert} closeAlert={() => { setShowAlert(false) }}>
                            {warningMessage}
                        </AlertComponent>
                        <div className="row testFormButtons">
                            <div className="col col-sm-2 btn-group btn-group-justified" role="group">
                                <button className="btn btn-sm btn-login" type={'submit'}>
                                    <span>{t('testPage.submit')}</span>
                                </button>
                            </div>
                            <div className="col col-sm-2 btn-group btn-group-justified">
                                <Link className="btn btn-sm btn-login" to={'/'}><span>{t('navigate.toContent')}</span></Link>
                            </div>
                        </div>
                    </form>
                </div>
            )
            :
            (
                <div>
                    <label>{t('testPage.noQuestions')}</label>
                    <div className="row testFormButtons">
                        <div className="col col-sm-2 btn-group btn-group-justified">
                            <Link className="btn btn-sm btn-login" to={'/'}><span>{t('navigate.toContent')}</span></Link>
                        </div>
                    </div>
                </div>
            )
    )
}