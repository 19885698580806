/**
 * This is the root page.
 */
import * as React from 'react';
import '../styles/RootPage.scss';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { TranslationContextProvider } from '@kuchenmeister/modules-react-translation';
import { LandingPage } from './LandingPage';
import { TestSubmittedPage } from './TestSubmittedPage';
import { LoginPage } from './LoginPage';
import { useStoreSelector } from '../store';

export interface IProps extends React.Props<any> { }

export const RootPage: React.FC<IProps> = (props) => {

    const user = useStoreSelector(_ => _.session);
    const response = useStoreSelector(_ => {
        return _.session.response
    });

    /** update the content (routes) */
    function updateContent() {
        let content = [];

        if (user && (response?.Success ?? false)) {
            content.push(<Route key={'landingPage'} path='*' element={<LandingPage />} />);
        } else {
            content.push(<Route key={'loginPage'} path='*' element={<LoginPage />} />);
        }
        content.push(<Route key={'testSubmit'} path='/test/submit' element={<TestSubmittedPage />} />)

        return content;
    }

    const [content, setContent] = React.useState(updateContent); 
    const [translations, setTranslation] = React.useState({});
    const [cssClasses, setCssClasses] = React.useState('default');

    React.useEffect(() => {
        // delay the change of the content, so that react has time to update the translation context
        // TODO: (If it is necessary); we could put the landingPage below the login page as route, and give there some info-banner like 'Login proceeding...' 
        setTimeout(() => { setContent(updateContent()); }, 25);
    }, [user, response]);

    React.useEffect(() => {
        setTranslation(response?.Context?.Translations);

        if (response?.Context?.CSSClasses)
            setCssClasses(['default', ...response.Context.CSSClasses].join(' '));
        else
            setCssClasses('default');
    }, [response]);

    return (
        <TranslationContextProvider
            id={'context'}
            translations={translations}
        >
            <div className={cssClasses}>
                <HashRouter>
                    <Routes>
                        {content}
                    </Routes>
                </HashRouter>
            </div>
        </TranslationContextProvider>
    );

}