import { tsGuard } from "@kuchenmeister/modules-react-effectstore";
import { effectSystem, storeActions } from "../store";
import { loginStory } from "./loginStory";
import { logoffStory } from "./logoffStory";
import { resumeStory } from "./resumeStory";

// main effects 1
export function* mainStory() {

    const f = tsGuard(effectSystem.effects.takeEvery(_ => storeActions.session.login.match(_), loginStory));
    f.value = yield f.effect;

    const f2 = tsGuard(effectSystem.effects.takeEvery(_ => storeActions.session.resume.match(_), resumeStory));
    f2.value = yield f2.effect;

    const f3 = tsGuard(effectSystem.effects.takeEvery(_ => storeActions.session.logoff.match(_), logoffStory));
    f3.value = yield f3.effect;

}
