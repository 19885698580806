/**
 * This is the login page.
 */
import * as React from 'react';
import '../styles/LoginPage.scss'
import { LanguageContextActions, useLanguageContext, useTranslation } from '@kuchenmeister/modules-react-translation';
import { AlertComponent, AlertTypes } from '../components/alertComponent';
import { IResumeRequest } from '../api';
import { sessionStore } from '../utils/LocalStorageHelper';
import { storeActions, useStore, useStoreSelector } from '../store';
import { useLanguages } from '@kuchenmeister/modules-react-translation/build/LanguageContextProvider';

export interface IProps extends React.Props<any> {

}

export const LoginPage: React.FC<IProps> = (props) => {

	const t = useTranslation();
	const lang = useLanguageContext();
	const Languages = useLanguages();
	const store = useStore();
	const response = useStoreSelector(function loginPageSelectResponse(_) { return _.session.response; });
	const user = useStoreSelector(function loginPageSelectUser(_) { return _.session.user; });

	const fName = React.useRef<HTMLInputElement>();
	const fSurname = React.useRef<HTMLInputElement>();
	const fEmployeeKey = React.useRef<HTMLInputElement>();
	const fShared = React.useRef<HTMLInputElement>();

	const [mode, setMode] = React.useState(0);
	const [alertType, setAlertType] = React.useState<AlertTypes>('warning');
	const [alertMessage, setAlertMessage] = React.useState("");
	const [showAlert, setShowAlert] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState('password' as 'password' | 'text'); 


	React.useEffect(() => {
		const session = sessionStore.load();
		if (session != null) {
			setMode(2);
			setAlertType('info');
			setAlertMessage('loginPage.sessionContinue');
			setShowAlert(true);

			store.dispatch(storeActions.session.resume({ session: session.Request }));
		}
		if (window.location.search?.length > 4) {
			try {
				fShared.current.value = atob(window.location.search.substring(1));
			} catch { }
		}
	}, []);

	function commit(event: React.FormEvent) {
		event.preventDefault();
		let user = {
			'Name': capatalizeFirstLetterLowerLastLetter(fName.current.value),
			'Surname': capatalizeFirstLetterLowerLastLetter(fSurname.current.value),
			'EmployeeKey': fEmployeeKey.current.value,
			'PresharedKey': fShared.current.value
		};

		store.dispatch(storeActions.session.login({ user }));
		setAlertMessage('loginPage.performing');
		setAlertType('info');
		setShowAlert(true);
		setMode(1);
	}

	React.useEffect(() => {
		if (!response || !user) return;

		if (response.Success) {
			let session: IResumeRequest = {
				SessionKey: response.Context.SessionToken,
				Name: user.Name,
				Surname: user.Surname,
				EmployeeKey: user.EmployeeKey
			}
			sessionStore.store({ Request: session });
			setShowAlert(false);
		} else {

			if (mode == 2)
				setAlertMessage('error.expiredSession');
			else
				setAlertMessage(response.Message);

			setAlertType('warning');
			setShowAlert(true);
			sessionStore.remove();
		}
	}, [response, user]);

	function changeLanguage(_languageKey: string) {
		lang.dispatch(LanguageContextActions.ChangeLanguage, { language: _languageKey });
	}

	function capatalizeFirstLetterLowerLastLetter(s: string) {
		if (s?.length >= 2)
			return s[0].toUpperCase() + s.substr(1).toLowerCase();
		return s;
	}

	function changeShowPassword() {
		setShowPassword(showPassword == 'password' ? 'text' : 'password');
	}

	return (
		<div className="container">
			<div className="row">
				<form className="form-horizontal" onSubmit={commit}>
					<fieldset>
						<div className="form-panel">
							<div className="site-heading">
								<h3>{t('loginPage.registration')}</h3>
							</div>

							<div className="form-row">
								<AlertComponent alertMessage={t(alertMessage as any)} showAlert={showAlert} alertType={alertType} closeAlert={() => { setShowAlert(false) }} />
							</div>

							<div className="form-row">
								<div className="input-group input-group-sm">
									<label className="form-label" title={t('loginPage.name')}>{t('loginPage.name')}</label>
									<input className="form-control" type={'text'} ref={fName} />
								</div>
							</div>

							<div className="form-row">
								<div className="input-group input-group-sm">
									<label className="form-label" title={t('loginPage.surname')}>{t('loginPage.surname')}</label>
									<input className="form-control" type={'text'} ref={fSurname} />
								</div>
							</div>

							<div className="form-row">
								<div className="input-group input-group-sm">
									<label className="form-label" title={t('loginPage.employeeKey')}>{t('loginPage.employeeKey')}</label>
									<input className="form-control" type={'text'} ref={fEmployeeKey} />
								</div>
							</div>

							<div className="form-row">
								<div className="input-group input-group-sm">
									<label className="form-label" title={t('loginPage.shared')} >{t('loginPage.shared')}</label>
									<input className="form-control" type={showPassword} ref={fShared} autoComplete={'off'} />
									<button className="btn btn-sm btn-login" type={'button'} onClick={(e) => changeShowPassword()}>
										<i className="far fa-eye"></i>
									</button>
								</div>
							</div>

							<div className="form-row">
								<div className="input-group input-group-sm">
									<label className="form-label">{t('loginPage.language')}</label>
									<select className="form-select" id="language" onChange={(e) => changeLanguage(e.target.value)} value={t.language}>
										{Languages.map(l => <option value={l.value} key={l.value}>{l.description}</option>)}
									</select>
								</div>
							</div>

							<button className="btn btn-sm btn-login btn-margin" type="submit">{t('loginPage.commit')}</button>
						</div>
					</fieldset>
				</form>
			</div>
		</div>
	)
}