//Request from 
export interface ITestSubmitRequest {
    SessionToken: string,
    Answers: { [key: string]: string },
}

export interface ITestSubmitResponseContext {
    Result: boolean,
    CorrectAnswers: number
    QuestionAnswers: number
}

export interface ITestSubmitResponse {
    Success: boolean,
    Message: string,
    Context: ITestSubmitResponseContext | null;
}

export function testSubmit(_request: ITestSubmitRequest): Promise<ITestSubmitResponse> {
    const promise = new Promise<ITestSubmitResponse>(async (resolve, reject) => {
        const response = await fetch('/api/test/submit', {
            method: 'POST',
            body: JSON.stringify(_request),
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            reject(response);
            return;
        }

        const jsonResponse = await response.json();
        resolve(jsonResponse);
    });
    (promise as any).name = `TestSubmitApi${0}`;
    return promise;
}
