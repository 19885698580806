import { StoreAction } from "@kuchenmeister/modules-react-effectstore";
import { Api, ILoginRequest, ILoginResponse } from "../api";
import { effectSystem, storeActions } from "../store";

// login story
export function* loginStory(action: StoreAction<{ user: ILoginRequest }>) {

    if (!action.payload.user.Name ||
        !action.payload.user.Surname ||
        !action.payload.user.PresharedKey ||
        !action.payload.user.EmployeeKey
    ) {
        yield effectSystem.effects.put(storeActions.session.loginFailed({ response: { Success: false, Message: 'error.missingLoginData', Context: null } }));
        return;
    }

    const response: ILoginResponse = yield effectSystem.effects.call(Api.login, action.payload.user);

    if (response.Success) {
        yield effectSystem.effects.put(storeActions.session.loginSuccess({ response }));
    } else {
        yield effectSystem.effects.put(storeActions.session.loginFailed({ response }));
    }
}
