import { StoreAction } from "@kuchenmeister/modules-react-effectstore";
import { Api, IResumeRequest, IResumeResponse } from "../api";
import { effectSystem, storeActions } from "../store";

// resume story
export function* resumeStory(action: StoreAction<{ session: IResumeRequest }>) {

    const response: IResumeResponse = yield effectSystem.effects.call(Api.resume, action.payload.session);

    if (response.Success) {
        yield effectSystem.effects.put(storeActions.session.resumeSuccess({ response }));
    } else {
        yield effectSystem.effects.put(storeActions.session.resumeFailed({ response }));
    }
}
